import {mapActions, mapGetters, mapMutations} from "vuex";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "new-password",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        password: '',
        password_confirmation: '',
        token: null,
        email: this.$route.query.email,
      },
      validationErrors: {},
    }
  },
  validations() {
    return {
      payload: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(20),
        },
        password_confirmation: {
          maxLength: maxLength(20),
          required,
          sameAsPassword: sameAs('password')
        },
      }
    }
  },
  computed: {
    ...mapGetters({}),
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      setNewPassword: `auth/SET_NEW_PASSWORD`
    }),
    ...mapMutations({
      changePopupNewPassword: `auth/CHANGE_POPUP_SET_NEW_PASSWORD`,
      changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
    }),

    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid){
        let obj = {};
        obj.password = this.payload.password;
        obj.password_confirmation = this.payload.password_confirmation;
        obj.token = this.$route.query.token;
        obj.email = this.$route.query.email;
        this.setNewPassword(obj)
          .then(() => {
            this.$toasted.success(this.$t('successChangePassword'));
            this.changePopupNewPassword(false);
            this.$router.replace({'query': null});
            setTimeout(()=> {
              this.changePopupLogin(true);
            },500)

          }).catch(error => {
          if (error.response.status === 422) {
            Object.assign(this.validationErrors, error.response.data.errors);
          }
          if (error.response.status === 400) {
            this.$toasted.error(error.response.data.message);
          }
        });
      }
    }
  },
  destroyed() {
    this.$router.replace({'query': null});
  }
}